import React from 'react'
import { Theme, AppBar, Box, Grid, Toolbar, useMediaQuery, useTheme, Button } from '@material-ui/core'
import { StartonTranslate } from '../StartonTranslate'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import useUser from 'data/user'
import { useRouter } from 'next/router'
import platform from '../../../../.tmp/platform'
//import Brightness2OutlinedIcon from '@mui/icons-material/Brightness2Outlined'
//import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/

interface IComponentProps {}

type StyleProps = IComponentProps
type StyleClassKey = 'container' | 'logo' | 'toolbar' | 'iconTheme' | 'button'
//type PropClasses = Record<StyleClassKey, string>

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/

export const NAVBAR_HEIGHT = 50

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => {
	return {
		button: {
			display: 'none',
			color: theme.palette.primary.main,
			borderColor: theme.palette.primary,
			opacity: 1,
			padding: '18px 18px',
			border: `1px solid ${theme.palette.primary}`,
			fontSize: '0.9em',
			boxSizing: 'border-box',
			fontWeight: 600,
			marginRight: '1em',
			[theme.breakpoints.down('md')]: {
				display: 'block',
			},
			[theme.breakpoints.down('sm')]: {
				display: 'none',
				//			width: '3em',
			},
		},
		container: {
			position: ['fixed', '!important'],
			boxShadow: ['none', '!important'],
			height: NAVBAR_HEIGHT,
		},
		logo: {
			display: 'none',
			[theme.breakpoints.down('md')]: {
				display: 'block',
			},
			[theme.breakpoints.down('sm')]: {
				display: 'block',
				width: '3em',
			},
		},
		iconTheme: {
			marginleft: 0,
			marginRight: 0,
			[theme.breakpoints.up('md')]: {
				marginLeft: '7px',
				marginRight: '10px',
			},
		},
		toolbar: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
	}
})

const StartonNavBar: React.FC<IComponentProps> = () => {
	const classes = useStyles({})
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const { t } = useTranslation()

	const router = useRouter()
	const { user, userError } = useUser()

	const redirect = () => {
		if (user) router.push(user?.returnUrl)
		if (userError) router.push(userError?.response?.data?.message)
	}

	return (
		<Box>
			<AppBar position="static" color="transparent" className={classes.container}>
				<Toolbar className={classes.toolbar}>
					<Grid item className={classes.logo}>
						{/*isDarkTheme ? (
								<img alt="logo Light" src="/images/logo-light-left-shadow.png" width={200} />
							) : (*/}
						<img
							alt="logo Dark"
							src={isMobile ? platform?.frontSettings?.logo?.small : platform?.frontSettings?.logo?.full}
							width={150}
							style={{
								cursor:
									userError?.response?.data?.errorCode !== 'LemonwayWrongOnBoardingIdException'
										? 'pointer'
										: 'inherit',
							}}
							onClick={() =>
								userError?.response?.data?.errorCode !== 'LemonwayWrongOnBoardingIdException'
									? redirect()
									: null
							}
						/>
					</Grid>
					<Grid item xs={12} sm={4} md={12} container justifyContent="flex-end" alignItems="center">
						<StartonTranslate />
						{userError?.response?.data?.errorCode !== 'LemonwayWrongOnBoardingIdException' && (
							<Button onClick={redirect} variant="text" className={classes.button} style={{}}>
								{t('tools.return_platform')}
							</Button>
						)}
						{/* @ts-ignore */}
						{/*}	<IconButton
							onClick={() => dispatch(setDarkTheme(!isDarkTheme))}
							size="medium"
							edge="start"
							color="inherit"
							aria-label="menu"
							className={classes.iconTheme}
						>
							{isDarkTheme ? (
								<LightModeOutlinedIcon style={{ color: 'white' }} />
							) : (
								<Brightness2OutlinedIcon />
							)}
						</IconButton> */}
					</Grid>
				</Toolbar>
			</AppBar>
		</Box>
	)
}

export { StartonNavBar }
