import * as React from 'react'
import { Theme, Typography, Button, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StartonLink } from 'components/Core'
import { useTranslation, Trans } from 'react-i18next'
import useUser from 'data/user'
import { useRouter } from 'next/router'

//import { useRouter } from 'next/router'
//import { AuthContentSocialConnect } from 'containers/Authentifications'

/*
|--------------------------------------------------------------------------
| PAGE
|--------------------------------------------------------------------------
*/

type StyleProps = Record<string, string>
type StyleClassKey = 'container' | 'button' | 'redirectionText' | 'redirectionTextDynamic'

const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>(() => ({
	container: {
		width: '100%',
		height: '100vh',
	},
	button: {
		marginTop: '2em',
	},
	redirectionText: {
		paddingTop: '2em',
		fontSize: '1.25rem',
		fontWeight: 500,
		lineHeight: 1.6,
	},
	redirectionTextDynamic: {
		fontSize: '1.25rem',
		fontWeight: 500,
		lineHeight: 1.6,
	},
}))

const StartonOnBoardingCompleted: React.FC = () => {
	const classes = useStyles({} as StyleProps)
	const { t } = useTranslation()
	const { user } = useUser()

	const [count, setCount] = React.useState(5)
	const myInterval: any = React.useRef() //WARNING TYPESCRIPT
	const router = useRouter()

	React.useEffect(() => {
		myInterval.current = setInterval(() => setCount((oldCount) => oldCount - 1), 1000)
	}, [])

	React.useEffect(() => {
		if (count === 0) {
			clearInterval(myInterval.current)
			router.push(user?.returnUrl)
		}
	}, [count])

	return (
		<React.Fragment>
			{user && (
				<Box>
					<Typography variant="h4"> {t('tools.OnBoarding_Registered')} </Typography>
					<Typography variant="h6" className={classes.redirectionText}>
						<Trans i18nKey="tools.completed_redirect" count={count} className={classes.redirectionText}>
							You will be redirected in
							<h1 className={classes.redirectionTextDynamic}>{{ count }}</h1>
						</Trans>
					</Typography>
					{count === 0 && (
						<Button
							className={classes.button}
							component={StartonLink}
							href={user?.returnUrl}
							color="primary"
						>
							{t('tools.return_platform')}
						</Button>
					)}
				</Box>
			)}
		</React.Fragment>
	)
}

export { StartonOnBoardingCompleted }
/*
|--------------------------------------------------------------------------
| GET STATIC PROPS GET SERVER
|--------------------------------------------------------------------------
*/
// handle a good pages/500 when token is not valid https://nextjs.org/docs/advanced-features/custom-error-page
// handle a loader with nprogress
