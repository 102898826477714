import * as React from 'react'
import { Button, Box, Grid, Typography, useTheme, Theme } from '@material-ui/core'
import platform from '../../../../.tmp/platform'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import useUser from 'data/user'
import { useRouter } from 'next/router'

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/

export interface IComponentProps {}

type StyleProps = Record<string, string>
type StyleClassKey = 'container'

const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => ({
	container: {
		backgroundColor: theme?.palette?.primary?.main,
		color: 'white',
	},
}))

const StartonKYCPannel: React.FC = () => {
	//const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
	//const { t } = useTranslation()

	const { user, userError } = useUser()

	const theme = useTheme()
	const { t } = useTranslation()
	const classes = useStyles({} as StyleProps)
	const router = useRouter()

	const redirect = () => {
		if (user) router.push(user?.returnUrl)
		if (userError) router.push(userError?.response?.data?.message)
	}

	return (
		<Box
			component={Grid}
			className={classes.container}
			// @ts-ignore
			item
			//style={{ backgroundColor: '#0497ff' }}
			md={3}
			container
			display={{ xs: 'none', sm: 'none', md: 'none', lg: 'flex' }}
			flexDirection="column"
			justifyContent="flex-start"
			wrap="nowrap"
			alignItems="stretch"
		>
			<Grid
				item
				xs={12}
				container
				justifyContent="flex-start"
				alignItems="flex-start"
				style={{ padding: '3.5em' }}
			>
				<div>
					{theme.palette.type === 'dark' ? (
						<img
							style={{ display: 'inline-block', marginBottom: '2em' }}
							alt="logo light"
							//src="/images/starton-logo.png"
							src={platform?.frontSettings?.logo?.full}
							width={120}
						/>
					) : (
						<img
							style={{ display: 'inline-block', marginBottom: '2em' }}
							alt="logo dark"
							//src="/images/starton-logo.png"
							src={platform?.frontSettings?.logo?.full}
							width={120}
						/>
					)}

					<Typography variant="h6">
						{/* Rocket Rides partners with Stripe for secure payments and financial services. */}
						{platform?.frontSettings?.auth?.rightTitle}
					</Typography>
				</div>
			</Grid>

			<Grid item xs={12} container justifyContent="center" alignItems="flex-end" style={{ padding: '3.5em' }}>
				{/* Powered by logo logo Contact English (AU) English (United Kingdom) */}
				{userError?.response?.data?.errorCode !== 'LemonwayWrongOnBoardingIdException' && (
					<Button
						onClick={redirect}
						variant="text"
						style={{
							color: 'white',
							opacity: 1,
							padding: '18px 18px',
							border: '1px solid white',
							fontSize: '0.9em',
							boxSizing: 'border-box',
							fontWeight: 600,
							marginRight: '1em',
						}}
					>
						{t('tools.return_platform')}
					</Button>
				)}
			</Grid>
		</Box>
	)
}

/*
|--------------------------------------------------------------------------
| GET STATIC PROPS GET SERVER
|--------------------------------------------------------------------------
*/

export { StartonKYCPannel }
