import * as React from 'react'
import { Grid, CardContent, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StartonCard } from 'components/Core'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
interface IUserProfileTileLayoutProps {
	children: React.ReactNode
}
type StyleProps = Record<string, unknown>
type StyleClassKey = 'stepIndex' | 'cardContainer' | 'myCardContent'
type PropClasses = Record<StyleClassKey, string>

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => ({
	stepIndex: {
		position: 'absolute',
		top: '3%',
		left: '3%',
		fontWeight: 700,
		fontSize: theme.typography.pxToRem(55),
		color: theme.palette.text.primary,
		opacity: 0.1,
	},
	cardContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		position: 'relative',
		textAlign: 'center',
		padding: theme.spacing(2, 4, 0),
		borderRadius: 0,
		boxShadow: '0px 0px 0px',
	},
	myCardContent: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		[theme.breakpoints.down('md')]: {
			justifyContent: 'center',
			alignItems: 'flex-end',
			paddingBottom: '0 !important',
		},
		[theme.breakpoints.down('sm')]: {
			paddingTop: 30,
		},
		paddingBottom: 0,
		padding: 0,
		flexWrap: 'wrap',
	},
}))

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const OnBoardingLayout: React.FC<IUserProfileTileLayoutProps> = (props: IUserProfileTileLayoutProps) => {
	const classes: PropClasses = useStyles({} as StyleProps)

	// Render
	// ----------------------------------------------------------------------------
	return (
		// <Box className="w-full mb-6">
		<StartonCard className={classes.cardContainer}>
			<CardContent className={classes.myCardContent}>
				<Grid item xs={12} sm={7} md={8}>
					{props.children}
				</Grid>
			</CardContent>
		</StartonCard>
		// </Box>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { OnBoardingLayout }
