/*
| Developed by Dirupt
| Filename : UserProfileSteps.styles.ts
| Author : DESPLATS Philippe (philippe@di-rupt.com)
*/

import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
// eslint-disable-next-line @typescript-eslint/ban-types
export type StyleProps = {}
export type StyleClassKey =
	| 'textFieldInput'
	| 'formTextContainer'
	| 'formSubTitle'
	| 'formHintText'
	| 'icon'
	| 'inputLabel'
	| 'phoneOptions'
export type PropClasses = Record<StyleClassKey, string>

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
export const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => ({
	textFieldInput: {
		marginBottom: theme.spacing(3),
		borderRadius: 100,
	},
	formTextContainer: {
		textAlign: 'left',
		margin: theme.spacing(3, 0),
	},
	formSubTitle: {
		color: theme.palette.primary.main,
		fontSize: theme.typography.pxToRem(18),
		fontWeight: 600,
	},
	formHintText: {
		fontSize: theme.typography.pxToRem(14),
		fontWeight: 400,
	},
	icon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(2),
	},
	inputLabel: {
		color: theme.palette.primary.main,
		fontSize: theme.typography.pxToRem(13),
	},
	phoneOptions: {
		...theme.typography.body1,
	},
}))
