import axios from 'axios'
import Router from 'next/router'

const starton = axios.create({
	baseURL: process.env.NEXT_PUBLIC_API_URL,
})

starton.interceptors.response.use(
	function (response) {
		// Do something with response data
		return response
	},
	function (error) {
		// Do something with response error
		if (
			error.response?.data?.errorCode === 'LemonwayWrongOnBoardingIdException' ||
			(error.response?.data?.errorCode === 'LemonwayExpiredOnBoardingIdException' &&
				Router.pathname !== 'error' &&
				Router.pathname !== '412' &&
				Router.pathname !== '404' &&
				Router.pathname !== '')
		) {
			if (typeof window !== 'undefined' && Router.query.token !== undefined)
				Router.push(`/error?token=${Router.query.token}`)
		}
		return Promise.reject(error)
	},
)

export default starton
