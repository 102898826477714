import * as React from 'react'
import dynamic from 'next/dynamic'
import { FieldProps } from 'formik'
import { TextFieldProps } from '@material-ui/core'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const MuiPhoneInput = dynamic(() => import('material-ui-phone-number'), { ssr: false })

export interface IStartonPhoneNumberInputProps extends FieldProps, Omit<TextFieldProps, 'name' | 'value' | 'error'> {}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const StartonPhoneNumberInput: React.FC<IStartonPhoneNumberInputProps> = ({ field, form, ...other }) => {
	const currentError = form.errors[field.name]

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	return (
		<MuiPhoneInput
			defaultCountry="fr"
			name={field.name}
			value={field.value}
			helperText={currentError}
			disabled={form.isSubmitting}
			error={Boolean(currentError)}
			onError={(error: string) => {
				// handle as a side effect
				if (error !== currentError) {
					form.setFieldError(field.name, error)
				}
			}}
			onChange={(value: string) => {
				form.setFieldValue(field.name, value)
			}}
			{...other}
		/>
	)
}
