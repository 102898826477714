import '../styles/fonts.css'
import '../styles/tailwind.css'
import * as React from 'react'
import App, { AppContext, AppInitialProps, AppProps } from 'next/app'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { DefaultSeo } from 'next-seo'
import CssBaseline from '@material-ui/core/CssBaseline'
import Head from 'next/head'
import { NextComponentType } from 'next'
import { Router, useRouter } from 'next/router'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { SnackbarProvider } from 'notistack'
import NProgress from 'nprogress'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import lightTheme from 'styles/lightTheme'
//import defaultMuiTheme from 'styles/defaultMUITheme'
import { StartonLayout } from 'components/Core'
import 'nprogress/nprogress.css' //styles of nprogress
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import fr from '../../.tmp/fr.json'
import en from '../../.tmp/en.json'
import { enUS, frFR } from '@material-ui/core/locale'
import { init } from 'helpers/sentry'
import platform from '../../.tmp/platform'
import starton from 'data/axios'
import useUser from 'data/user'
//import App from 'next/app'

init()

const StartonApp: NextComponentType<AppContext, AppInitialProps, AppProps> = (appProps: AppProps) => {
	// Remove the server-side injected CSS
	// ----------------------------------------------------------------------------

	const router = useRouter()

	starton.defaults.headers.common['x-on-boarding-id'] = router.query.token
	//@ts-ignore
	const {} = useUser(appProps.userData)

	React.useEffect(() => {
		const jssStyles = document.querySelector('#jss-server-side')

		if (jssStyles) {
			jssStyles.parentElement?.removeChild(jssStyles)
		}

		Sentry.init({
			dsn:
				process.env.NEXT_PUBLIC_SENTRY_DSN ||
				'https://0a1e7e4747624423a9ca83066a91c212@o356009.ingest.sentry.io/5582558',
			autoSessionTracking: true,
			integrations: [new Integrations.BrowserTracing()],
			// We recommend adjusting this value in production, or using tracesSampler
			// for finer control
			tracesSampleRate: 1.0,
		})
	}, [])

	//let currentTheme = isDarkTheme ? darkTheme : lightTheme
	//let currentTheme = isDarkTheme ? darkTheme : { ...lightTheme, ...platform?.frontSettings?.theme } // override light Theme with platform frontSettings theme
	let currentTheme = { ...lightTheme, ...platform?.frontSettings?.theme }

	i18n.use(initReactI18next) // passes i18n down to react-i18next
		.init({
			resources: {
				en: {
					translation: en,
				},
				fr: {
					translation: fr,
				},
			},
			lng: router.locale,
			keySeparator: false, // we do not use keys in form messages.welcome
			interpolation: {
				escapeValue: false, // react already safes from xss
			},
		})

	// Render
	// ----------------------------------------------------------------------------
	return (
		<React.Fragment>
			<Head>
				<meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
				<link
					rel="shortcut icon"
					href={platform?.frontSettings?.logo?.favicon || platform?.frontSettings?.logo?.small}
				/>
			</Head>
			<DefaultSeo
				titleTemplate={platform?.frontSettings?.seo?.titleTemplate || `${platform?.frontSettings?.name} | %s`}
				openGraph={{
					type: platform?.frontSettings?.seo?.openGraph?.type || 'website',
					locale: platform?.frontSettings?.seo?.openGraph?.locale || 'en',
					url: platform?.frontSettings?.seo?.openGraph?.url || '',
					site_name: platform?.frontSettings?.seo?.openGraph?.siteName || platform?.frontSettings?.name,
				}}
				twitter={{
					handle: platform?.frontSettings?.seo?.twitter?.handle || '',
					site: platform?.frontSettings?.seo?.twitter?.site || '@site',
					cardType: platform?.frontSettings?.seo?.twitter?.cardType || 'summary_large_image',
				}}
			/>
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<ThemeProvider
					theme={createTheme(
						{
							...currentTheme,
						},
						router.locale === 'fr' ? frFR : enUS,
					)}
				>
					<SnackbarProvider
						/*	classes={{
						variantSuccess: classes.success,
						variantError: classes.error,
						variantWarning: classes.warning,
						variantInfo: classes.info,
		}}*/
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						maxSnack={3}
					>
						<CssBaseline />
						<StartonLayout {...appProps} />
					</SnackbarProvider>
				</ThemeProvider>
			</MuiPickersUtilsProvider>
		</React.Fragment>
	)
}

StartonApp.getInitialProps = async (appContext) => {
	const appProps = await App.getInitialProps(appContext)
	let response = null
	if (
		appContext?.ctx?.pathname !== '/412' &&
		appContext?.ctx?.pathname !== '/404' &&
		appContext?.ctx?.pathname !== '/error'
	)
		try {
			response = await starton.get(`/lemonway/account/get-on-boarding-data`, {
				headers: {
					'x-on-boarding-id': appContext.router.query.token,
				},
			})
			starton.defaults.headers.common['x-on-boarding-id'] = appContext.router.query.token
		} catch (e) {}

	return {
		...appProps,
		userData: response?.data,
	}
}

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

/*
|--------------------------------------------------------------------------
| EXPORT APP LAYOUT WITH REDUX AND TRANSLATION
|--------------------------------------------------------------------------
*/
export default StartonApp
