import * as React from 'react'
import * as Yup from 'yup'
import { FormikHelpers } from 'formik/dist/types'
import { Field, Formik, Form } from 'formik'
import { Box, FormControl, InputLabel, MenuItem, Typography } from '@material-ui/core'
import { TextField, Select } from 'formik-material-ui'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { StartonButton } from 'components/Core'
import { PropClasses, StyleProps, useStyles } from 'containers/User/Settings/UserProfile/Steps/UserProfileSteps.styles'
import { useSnackbar } from 'notistack'
import useUser from 'data/user'
import starton from 'data/axios'
import countries from 'constants/countries'
import { OnBoardingLayout } from 'containers/onBoarding'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
export interface IUserProfileAddressInformations {
	street: string
	postCode: string
	city: string
	country: string
}
/*
|--------------------------------------------------------------------------
| SCHEMA VALIDATIONS
|--------------------------------------------------------------------------
*/
const schemaValidations = Yup.object().shape({
	street: Yup.string().required('Field is required'),
	//street1: Yup.string().notRequired(),
	country: Yup.string().required('Field is required'),
	city: Yup.string().required('Field is required'),
	postCode: Yup.string().required('Field is required'),
})

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const StartonAddressInformations: React.FC = () => {
	const classes: PropClasses = useStyles({} as StyleProps)
	const router = useRouter()
	const { enqueueSnackbar } = useSnackbar()
	const { user, /*userIsLoading,*/ userError } = useUser()
	const { t } = useTranslation()

	if (userError)
		enqueueSnackbar(t(`${userError?.response?.data?.errorCode}`), {
			variant: 'error',
		})

	const initialValues = React.useMemo<IUserProfileAddressInformations>(() => {
		return {
			street: user?.lemonwayAccount?.adresse?.street ?? '',
			postCode: user?.lemonwayAccount?.adresse?.postCode ?? '',
			city: user?.lemonwayAccount?.adresse?.city ?? '',
			country: user?.lemonwayAccount?.adresse?.country ?? '',
		}
	}, [user])

	const handleBack = () => {
		router.push(`/birth-informations?token=${router.query.token}`)
	}

	const handleSubmit = async (
		values: IUserProfileAddressInformations,
		formikHelpers: FormikHelpers<IUserProfileAddressInformations>,
	) => {
		formikHelpers.setSubmitting(true)
		try {
			await starton.patch(`/lemonway/account`, {
				adresse: {
					...values,
				},
			})
			await starton.post(`/lemonway/account/validate`, null)
			router.push(`kyc?token=${router.query.token}`)
		} catch (e) {
			if (
				e.response.data.message ===
				'Code: 252 - Please send real first and last names (different and with more than 1 character)'
			)
				enqueueSnackbar(t('LemonwayErrorOnNames'), {
					variant: 'error',
				})
			else
				enqueueSnackbar(t(`${e?.response?.data?.errorCode}`), {
					variant: 'error',
				})
			formikHelpers.setSubmitting(false)
		}
	}

	// Render
	// ----------------------------------------------------------------------------
	return (
		<OnBoardingLayout>
			{user && (
				<Formik initialValues={initialValues} validationSchema={schemaValidations} onSubmit={handleSubmit}>
					{(formikProps) => (
						<Form id="-personal-informations">
							<Box className={classes.formTextContainer}>
								<Typography className={classes.formSubTitle}>
									{t('profile.your_personal_address')}
								</Typography>
								<Typography className={classes.formHintText}>{t('kyc.why_we_ask')}</Typography>
							</Box>
							<Box className="flex flex-row justify-center"></Box>
							<Box>
								<Field
									component={TextField}
									className={classes.textFieldInput}
									id="street"
									name="street"
									label={t('profile.your_street_address')}
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									fullWidth
								/>
								{/*<Field
								component={TextField}
								className={classes.textFieldInput}
								id="street1"
								name="street1"
								label={t('profile.your_second_street_address')}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								fullWidth
							/> */}
								<FormControl variant="outlined" className={classes.textFieldInput} fullWidth>
									<InputLabel htmlFor="country">{t('profile.your_country')}</InputLabel>
									<Field
										component={Select}
										name="country"
										variant="outlined"
										label="Country"
										inputProps={{
											label: t('profile.your_country'),
											labelId: 'country',
											id: 'country',
										}}
									>
										{countries.map((country) => {
											return (
												<MenuItem key={country.alpha2Code} value={country.alpha3Code}>
													{country.enShortName}
												</MenuItem>
											)
										})}
									</Field>
								</FormControl>
								<Field
									component={TextField}
									className={classes.textFieldInput}
									id="city"
									name="city"
									label={t('profile.your_city')}
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									fullWidth
								/>
								<Field
									component={TextField}
									className={classes.textFieldInput}
									id="postCode"
									name="postCode"
									label={t('profile.your_zip_code')}
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									fullWidth
								/>
							</Box>
							{/*)}*/}
							<Box className="mt-6 w-full flex flex-col md:flex-row">
								<StartonButton
									className="order-2 md:order-1 mt-4 md:mt-0"
									variant="outlined"
									onClick={handleBack}
								>
									{t('tools.back')}
								</StartonButton>
								<StartonButton
									type="submit"
									className="order-1 md:order-2 md:ml-4 flex-auto md:flex-1"
									disabled={formikProps.isSubmitting || !formikProps.isValid}
									loading={formikProps.isSubmitting}
								>
									{t('tools.submit')}
								</StartonButton>
							</Box>
						</Form>
					)}
				</Formik>
			)}
		</OnBoardingLayout>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { StartonAddressInformations }
