/*
| Developed by Dirupt
| Filename : StartonMarkdown.tsx
| Description : Starton markdown viewer
| Author : DESPLATS Philippe (philippe@di-rupt.com)
*/

import React from 'react'
import ReactMarkdown from 'markdown-to-jsx'
import { Theme, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
type CreateElement = typeof React.createElement
type HTMLTags = keyof JSX.IntrinsicElements
/**
 * RequireAtLeastOne<{ ... }> <- only requires at least one key
 */
type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
	{
		[K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>
	}[Keys]
type Override =
	| RequireAtLeastOne<{
			component: React.ComponentType<any>
			props: Record<string, any>
	  }>
	| React.ComponentType<any>
type Overrides = {
	[tag in HTMLTags]?: Override
} & {
	[customComponent: string]: Override
}
type Options = Partial<{
	/**
	 * Ultimate control over the output of all rendered JSX.
	 */
	createElement: (
		tag: Parameters<CreateElement>[0],
		props: React.Props<any>,
		...children: React.ReactChild[]
	) => JSX.Element
	/**
	 * Disable the compiler's best-effort transcription of provided raw HTML
	 * into JSX-equivalent. This is the functionality that prevents the need to
	 * use `dangerouslySetInnerHTML` in React.
	 */
	disableParsingRawHTML: boolean
	/**
	 * Forces the compiler to always output content with a block-level wrapper
	 * (`<p>` or any block-level syntax your markdown already contains.)
	 */
	forceBlock: boolean
	/**
	 * Forces the compiler to always output content with an inline wrapper (`<span>`)
	 */
	forceInline: boolean
	/**
	 * Supply additional HTML entity: unicode replacement mappings.
	 *
	 * Pass only the inner part of the entity as the key,
	 * e.g. `&le;` -> `{ "le": "\u2264" }`
	 *
	 * By default
	 * the following entites are replaced with their unicode equivalents:
	 *
	 * ```
	 * &amp;
	 * &apos;
	 * &gt;
	 * &lt;
	 * &nbsp;
	 * &quot;
	 * ```
	 */
	namedCodesToUnicode: {
		[key: string]: string
	}
	/**
	 * Selectively control the output of particular HTML tags as they would be
	 * emitted by the compiler.
	 */
	overrides: Overrides
	/**
	 * Override normalization of non-URI-safe characters for use in generating
	 * HTML IDs for anchor linking purposes.
	 */
	slugify: (source: string) => string
}>
export interface IStartonMarkdownProps {
	[key: string]: any
	children: string
	options?: Options
}

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const styles = (theme: Theme) => ({
	listItem: {
		marginTop: theme.spacing(1),
	},
})

/*
|--------------------------------------------------------------------------
| OPTIONS
|--------------------------------------------------------------------------
*/
const OPTIONS = {
	overrides: {
		h1: {
			component: Typography,
			props: {
				gutterBottom: true,
				variant: 'h5',
			},
		},
		h2: { component: Typography, props: { gutterBottom: true, variant: 'h6' } },
		h3: { component: Typography, props: { gutterBottom: true, variant: 'subtitle1' } },
		h4: {
			component: Typography,
			props: { gutterBottom: true, variant: 'caption', paragraph: true },
		},
		p: { component: Typography, props: { paragraph: true } },
		a: { component: Link },
		li: {
			component: withStyles(styles)(({ classes, ...props }: any) => (
				<li className={classes.listItem}>
					<Typography component="span" {...props} />
				</li>
			)),
		},
	},
}

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const StartonMarkdown: React.FC<IStartonMarkdownProps> = (props: IStartonMarkdownProps) => {
	return <ReactMarkdown options={OPTIONS} {...props} />
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { StartonMarkdown }
