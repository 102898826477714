import useSWR from 'swr'
import fetcher from 'data/fetcher'
import { UserEntity } from 'model/userEntity'

export default function useUser(initialData?: any) {
	const { data, error, mutate } = useSWR(`/lemonway/account/get-on-boarding-data`, fetcher, {
		revalidateOnFocus: false,
		fallbackData: initialData,
	})

	return {
		user: data as UserEntity,
		mutate,
		userIsLoading: !error && !data,
		userError: error,
	}
}
