import React from 'react'
import { CardContent, Typography } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { useTranslation } from 'react-i18next'
/*import Metamask from 'public/images/metamask.svg'
import Portis from 'public/images/portis.svg'
import WalletConnect from 'public/images/walletConnect.svg'
import WalletLink from 'public/images/walletLink.svg'*/
import { withStyles } from '@material-ui/styles'
import { StartonCard } from '../StartonCard'

const style = (theme) => ({
	container: {
		width: '100%',
		maxWidth: 600,
		borderRadius: ['12px', '!important'],
		[theme.breakpoints.down('xs')]: {
			paddingTop: 70,
		},
		[theme.breakpoints.down(350)]: {
			paddingTop: [350, '!important'],
		},
	},
	cardContent: {
		padding: [50, '!important'],
	},
	listItem: {
		display: 'flex',
		justifyContent: ['center', '!important'],
	},
	listItemGutters: {
		padding: [20, '!important'],
	},
	listItemText: {
		alignItems: ['end', '!important'],
		paddingLeft: '4%',
		paddingTop: '10px',
		fontSize: '1.12em',
		maxWidth: '70%',
	},
})

const CardSigning = (props) => {
	const { classes } = props
	const { t } = useTranslation()
	const web3Providers = [
		{
			img: '/images/metamask.svg',
			text: t('signing.descriptionMetaMaskk'),
		},
		{
			img: '/images/portis.svg',
			text: t('signing.descriptionPortis'),
		},
		{
			img: '/images/walletConnect.svg',
			text: t('signing.descriptionWalletConnect'),
		},
		{
			img: '/images/walletLink.svg',
			text: t('signing.descriptionPortis'),
		},
	]

	return (
		<StartonCard className={classes.container}>
			<CardContent classes={{ root: classes.cardContent }}>
				<Typography variant="h4" align="center" style={{ fontWeight: 600, fontSize: '1.85rem' }} gutterBottom>
					{t('signing.portailTitle')}
				</Typography>
				<Typography variant="h6" align="center" gutterBottom>
					{t('signing.portailText')}
				</Typography>
				<List>
					{web3Providers.map((provider, index) => {
						return (
							<ListItem
								key={index}
								classes={{ gutters: classes.listItemGutters, root: classes.listItem }}
								role={undefined}
								alignItems="center"
								divider
								button
							>
								<ListItemIcon>
									<img
										alt={''}
										src={provider.img}
										width={40}
										height={40}
										style={{ height: '40px' }}
									></img>
								</ListItemIcon>
								<ListItemText
									disableTypography
									classes={{ root: classes.listItemText }}
									primary={<Typography variant="p">{provider.text}</Typography>}
								/>
							</ListItem>
						)
					})}
				</List>
			</CardContent>
		</StartonCard>
	)
}

export default withStyles(style)(CardSigning)
