import * as React from 'react'
import * as Yup from 'yup'
import { Grid, Box, FormControlLabel, Radio, Typography } from '@material-ui/core'
import { FormikHelpers } from 'formik/dist/types'
import { Field, Form, Formik } from 'formik'
import { RadioGroup, TextField } from 'formik-material-ui'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import useUser from 'data/user'
import starton from 'data/axios'
import { PropClasses, StyleProps, useStyles } from 'containers/User/Settings/UserProfile/Steps/UserProfileSteps.styles'
import { StartonButton } from 'components/Core'
import { StartonPhoneNumberInput } from 'components/Core/StartonPhoneNumberInput/startonPhoneNumberInput'
import { OnBoardingLayout } from 'containers/onBoarding'
import { useSnackbar } from 'notistack'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
export interface IUserProfileIdentityInformations {
	type: 'individual'
	title: 'M' | 'F' | ''
	firstName: string
	lastName: string
	middleName?: string
	phoneNumber: string
}

interface IUserProfilePersonalInformationsProps {
	initialUser: any //WARNING GET THE EXACT TYPESCRIPT FROM THE API HERE
}

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const StartonIdentityInformations: React.FC<IUserProfilePersonalInformationsProps> = (
	props: IUserProfilePersonalInformationsProps,
) => {
	const classes: PropClasses = useStyles({} as StyleProps)
	const router = useRouter()
	const { user, /*mutate,*/ /*userIsLoading,*/ userError } = useUser()
	const { enqueueSnackbar } = useSnackbar()
	const { t } = useTranslation()

	if (userError)
		enqueueSnackbar(t(`${userError?.response?.data?.errorCode}`), {
			variant: 'error',
		})

	const currentUser = props.initialUser ? props.initialUser : user

	const initialValues = React.useMemo<IUserProfileIdentityInformations>(() => {
		return {
			type: 'individual',
			email: currentUser?.lemonwayAccount.email ?? '',
			title: currentUser?.lemonwayAccount.title ?? '',
			firstName: currentUser?.lemonwayAccount.firstName ?? '',
			lastName: currentUser?.lemonwayAccount.lastName ?? '',
			//middleName: currentUser?.lemonwayAccount.middleName ?? '',
			phoneNumber: currentUser?.lemonwayAccount.phoneNumber ?? '',
		}
	}, [])

	// Methods
	// ----------------------------------------------------------------------------

	const schemaValidations = Yup.object().shape({
		/*type: Yup.string()
			.oneOf(['individual', 'company'], 'Please select "Individual Account" or "Company Account"')
			.required('Field is required'),*/
		title: Yup.string().oneOf(['M', 'F'], t('tools.man_or_woman')).required(t('tools.field_required')),
		firstName: Yup.string().required(t('tools.field_required')),
		lastName: Yup.string().required(t('tools.field_required')),
		//middleName: Yup.string().notRequired(),
		email: Yup.string().email('Invalid email').required('Field is required'),
		phoneNumber: Yup.string().required(t('tools.field_required')),
		// phone: Yup.string().matches(PHONE_REG_EXP, t('tools.invalid_phone_number')).required(t('tools.field_required')),
	})

	const handleSubmit = async (
		values: IUserProfileIdentityInformations,
		formikHelpers: FormikHelpers<IUserProfileIdentityInformations>,
	) => {
		// Set submitting
		formikHelpers.setSubmitting(true)

		// Try to save data
		try {
			await starton.patch(`/lemonway/account`, {
				...values,
			})
			router.push(`/birth-informations?token=${router.query.token}`)
		} catch (e) {
			if (e?.response?.data?.message[0] === 'phoneNumber must be a valid phone number')
				enqueueSnackbar(t('LemonwayBadPhoneNumber'), {
					variant: 'error',
				})
			else
				enqueueSnackbar(t(`${e?.response?.data?.errorCode}`), {
					variant: 'error',
				})
			formikHelpers.setSubmitting(false)
		}
	}

	// Render
	// ----------------------------------------------------------------------------
	return (
		<OnBoardingLayout>
			<Formik initialValues={initialValues} validationSchema={schemaValidations} onSubmit={handleSubmit}>
				{(formikProps) => (
					<Form id="-personal-informations">
						{/*<Box className={classes.formTextContainer}>
							<Typography className={classes.formSubTitle}>{t('profile.your_account_type')}</Typography>
							<Typography className={classes.formHintText}>{t('profile.why_we_ask')}</Typography>
						</Box>
						<Field
							component={RadioGroup}
							name="accountType"
							className="flex flex-col justify-around w-full mt-6"
						>
							<FormControlLabel
								control={<Radio disabled={formikProps.isSubmitting} />}
								label={t('profile.individual_account')}
								value="individual"
								disabled={formikProps.isSubmitting}
								checked
							/>
							<FormControlLabel
								control={<Radio disabled={formikProps.isSubmitting} />}
								label={t('profile.company_account')}
								value="company"
								disabled
							/>
				</Field> */}
						<Box className={classes.formTextContainer}>
							<Typography className={classes.formSubTitle}>
								{t('profile.privacy_informations')}
							</Typography>
							<Typography className={classes.formHintText}>
								{t('profile.privacy_informations_policies')}
							</Typography>
						</Box>
						<Box className={clsx('w-full flex flex-col items-start', classes.textFieldInput)}>
							<Typography className={classes.inputLabel}>{t('profile.gender')}</Typography>
							<Field
								component={RadioGroup}
								name="title"
								row
								className="flex flex-row justify-around w-full"
							>
								<FormControlLabel
									control={<Radio disabled={formikProps.isSubmitting} />}
									label={t('tools.man')}
									value="M"
									disabled={formikProps.isSubmitting}
								/>
								<FormControlLabel
									control={<Radio disabled={formikProps.isSubmitting} />}
									label={t('tools.woman')}
									value="F"
									disabled={formikProps.isSubmitting}
								/>
							</Field>
						</Box>
						<Field
							component={TextField}
							className={classes.textFieldInput}
							id="firstName"
							name="firstName"
							label={t('profile.first_name')}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							fullWidth
						/>
						<Field
							component={TextField}
							className={classes.textFieldInput}
							id="lastName"
							name="lastName"
							label={t('profile.last_name')}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							fullWidth
						/>
						{/* <Field
							component={TextField}
							className={classes.textFieldInput}
							id="middleName"
							name="middleName"
							label={t('profile.middle_name')}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							fullWidth
						/> */}
						<Field
							component={TextField}
							className={classes.textFieldInput}
							type="email"
							id="email"
							name="email"
							label={t('profile.email')}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							fullWidth
						/>
						<Field
							component={StartonPhoneNumberInput}
							className={classes.textFieldInput}
							id="phoneNumber"
							name="phoneNumber"
							label={t('profile.your_phone_number')}
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							fullWidth
						/>

						<Grid container justifyContent="center">
							<Grid item xs={12} sm={12} md={9}>
								<StartonButton
									type="submit"
									disabled={formikProps.isSubmitting || !formikProps.isValid}
									loading={formikProps.isSubmitting}
									className="w-full"
								>
									{t('profile.next')}
								</StartonButton>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</OnBoardingLayout>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { StartonIdentityInformations }
