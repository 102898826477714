import * as React from 'react'
import * as Yup from 'yup'
import { Box, FormControl, InputLabel, MenuItem, Typography } from '@material-ui/core'
import { FormikHelpers, FormikProps } from 'formik/dist/types'
import { Field, Form, Formik } from 'formik'
import { Select, TextField } from 'formik-material-ui'
import { DatePicker } from 'formik-material-ui-pickers'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import { PropClasses, StyleProps, useStyles } from 'containers/User/Settings/UserProfile/Steps/UserProfileSteps.styles'
import { StartonButton } from 'components/Core'
import countries from 'constants/countries'
import starton from 'data/axios'
import useUser from 'data/user'
import { OnBoardingLayout } from 'containers/onBoarding'
import { useSnackbar } from 'notistack'

export interface IUserProfileBirthInformationsForm {
	nationality: string
	date: Date | string
	city: string
	country: string
}

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const StartonBirthInformations: React.FC = () => {
	const classes: PropClasses = useStyles({} as StyleProps)
	const router = useRouter()
	const { user, /*mutate, userIsLoading,*/ userError } = useUser()
	const { enqueueSnackbar } = useSnackbar()
	const { t } = useTranslation()

	if (userError)
		enqueueSnackbar(t(`${userError?.response?.data?.errorCode}`), {
			variant: 'error',
		})

	const initialValues = React.useMemo<IUserProfileBirthInformationsForm>(() => {
		return {
			nationality: user?.lemonwayAccount?.nationality ? user?.lemonwayAccount?.nationality : '',
			date: user?.lemonwayAccount?.Birth?.date ? new Date(user?.lemonwayAccount?.Birth?.date) : '',
			city: user?.lemonwayAccount?.Birth?.city ? user?.lemonwayAccount?.Birth?.city : '',
			country: user?.lemonwayAccount?.Birth?.country ? user?.lemonwayAccount?.Birth?.country : '',
		}
	}, [user])

	const handleBack = () => {
		router.push(`/identity-informations?token=${router.query.token}`)
	}

	const schemaValidations = Yup.object().shape({
		date: Yup.date()
			.required(t('tools.field_required'))
			.test('age', t('tools.majority_restriction'), (value) => {
				return moment().diff(moment(value), 'years') >= 18
			}),
		city: Yup.string().required(t('tools.field_required')),
		country: Yup.string().required(t('tools.field_required')),
		nationality: Yup.string().required(t('tools.field_required')),
	})

	const handleSubmit = async (
		values: IUserProfileBirthInformationsForm,
		formikHelpers: FormikHelpers<IUserProfileBirthInformationsForm>,
	) => {
		// Set submitting
		formikHelpers.setSubmitting(true)
		// Try to save data
		try {
			await starton.patch(`/lemonway/account`, {
				nationality: values.nationality,
				Birth: {
					...values,
				},
			})
			router.push(`/address-informations?token=${router.query.token}`)
		} catch (e) {
			enqueueSnackbar(t(`${e?.response?.data?.errorCode}`), {
				variant: 'error',
			})
			formikHelpers.setSubmitting(false)
		}
	}

	// Render
	// ----------------------------------------------------------------------------
	return (
		<OnBoardingLayout>
			{user && (
				<Formik initialValues={initialValues} validationSchema={schemaValidations} onSubmit={handleSubmit}>
					{(formikProps: FormikProps<IUserProfileBirthInformationsForm>) => (
						<Form id="-personal-informations">
							<Box className={classes.formTextContainer}>
								<Typography className={classes.formSubTitle}>
									{t('profile.your_birth_informations')}
								</Typography>
								<Typography className={classes.formHintText}>
									{t('profile.privacy_informations_policies')}
								</Typography>
							</Box>
							<Field
								component={DatePicker}
								className={classes.textFieldInput}
								id="birthDate"
								name="date"
								label={t('profile.birth_date')}
								inputVariant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								disableFuture
								openTo="year"
								format="DD/MM/yyyy"
								views={['year', 'month', 'date']}
								fullWidth
							/>
							<FormControl variant="outlined" className={classes.textFieldInput} fullWidth>
								<InputLabel htmlFor="birthCountry">{t('profile.birth_country')}</InputLabel>
								<Field
									component={Select}
									name="country"
									variant="outlined"
									label="Nationality"
									inputProps={{
										label: t('profile.birth_country'),
										labelId: 'birthCountry',
										id: 'birthCountry',
									}}
								>
									{countries.map((country) => {
										return (
											<MenuItem key={country.alpha2Code} value={country.alpha3Code}>
												{country.enShortName}
											</MenuItem>
										)
									})}
								</Field>
							</FormControl>
							<Field
								component={TextField}
								className={classes.textFieldInput}
								id="birthCity"
								name="city"
								label={t('profile.birth_city')}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								fullWidth
							/>

							<FormControl variant="outlined" className={classes.textFieldInput} fullWidth>
								<InputLabel htmlFor="nationality">{t('profile.nationality')}</InputLabel>
								<Field
									component={Select}
									name="nationality"
									variant="outlined"
									label="Nationality"
									inputProps={{
										label: t('profile.nationality'),
										labelId: 'nationality',
										id: 'nationality',
									}}
								>
									{countries.map((country) => {
										return (
											<MenuItem key={country.alpha2Code} value={country.alpha3Code}>
												{country.nationality}
											</MenuItem>
										)
									})}
								</Field>
							</FormControl>
							<Box className="mt-6 w-full flex flex-col md:flex-row">
								<StartonButton
									className="order-2 md:order-1 mt-4 md:mt-0"
									variant="outlined"
									onClick={handleBack}
								>
									{t('tools.back')}
								</StartonButton>
								<StartonButton
									type="submit"
									className="order-1 md:order-2 md:ml-4 flex-auto md:flex-1"
									disabled={formikProps.isSubmitting || !formikProps.isValid}
									loading={formikProps.isSubmitting}
								>
									{t('profile.next')}
								</StartonButton>
							</Box>
						</Form>
					)}
				</Formik>
			)}
		</OnBoardingLayout>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { StartonBirthInformations }
